import { Injectable } from '@angular/core';

import firebase from "firebase/app";
import "firebase/firestore";

import { Observable, from } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  // ***** this should be the only place where the year is coded/updated
  runnersRef = firebase.firestore().collection('2021');
  settingsRef = firebase.firestore().collection('settings');
  videosRef = firebase.firestore().collection('videos');
  photosRef = firebase.firestore().collection('photos');

  public raceYear: string = '2021';

  constructor() {
    console.debug('firebase.service.ts loading...');
    this.initialize();
  }

  initialize() {
    this.getSettings('start').then((result) => {
      if (result && result.year) {
        this.runnersRef = firebase.firestore().collection(result.year);
        this.raceYear = result.year;
      }
    })
  }

  /**
  * Returns Promise snapshot with json collection
  */
  getSettings(key: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      this.settingsRef.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          if (doc.id === key) {
            resolve(doc.data());
          }
          // console.log(doc.id, " => ", doc.data());
        });
      }).catch(err => {
        console.error('firebase.service.ts getLiveSettings() error: ', err);
        resolve(null);
      })

    });
  }

  /**
  * Returns Promise snapshot with json collection
  */
  getRunnersCollection(): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      this.runnersRef.get().then(function (querySnapshot) {
        let runners = [];
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          runners.push(doc.data());
          // resolve(doc.data());
        });
        runners.sort((a, b) => (a.last > b.last) ? 1 : -1)
        if (runners.length > 0) {
          resolve(runners);
        } else {
          resolve(null);
        }
      }).catch(err => {
        console.error('firebase.service.ts getRunnersCollection() error: ', err);
        resolve(null);
      })

    });
  }

  /**
  * Returns Promise snapshot with json collection
  */
   getVideosCollection(): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      this.videosRef.get().then(function (querySnapshot) {
        let videos = [];
        querySnapshot.forEach(function (doc) {
          videos.push(doc.id);
        });
        resolve(videos);
      }).catch(err => {
        console.error('firebase.service.ts getVideosCollection() error: ', err);
        resolve(null);
      })

    });
  }

  /**
  * Returns Promise snapshot with json collection
  */
   getPhotosCollection(): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      this.photosRef.get().then(function (querySnapshot) {
        let photos = [];
        querySnapshot.forEach(function (doc) {
          photos.push(doc.id);
        });
        resolve(photos);
      }).catch(err => {
        console.error('firebase.service.ts getPhotosCollection() error: ', err);
        resolve(null);
      })

    });
  }

  /**
   * Returns a given Runner observable given a doc id
   */
  getRunnerByIdObservable(id: string): Observable<any> {

    return new Observable((observer) => {
      this.runnersRef.doc(id).onSnapshot(function (doc) {
        let data = doc.data();
        if (data !== undefined) {
          observer.next({
            bib: data.bib,
            year: data.year,
            first: data.first,
            last: data.last,
            display: data.display,
            gender: data.gender,
            age: data.age,
            city: data.city,
            state: data.state,
            country: data.country,
            wserId: data.guid,
            status: data.status,
            statusId: data.statusId,
            position: data.position,
            lastUpdate: '',
            img: data.img,
            station: data.station,
            distance: data.distance,
            raceTime: data.raceTime,
            dayTime: data.dayTime,
            splits: data.splits
          });
        } else {
          // what happens if there is no match
          console.error('firebase.service.ts getRunnerByIdObservable() data undefined');
        }
      });
    });

  }

  /**
   * Returns an alert observable
   */
   getAlertObservable(): Observable<any> {

    return new Observable((observer) => {
      this.settingsRef.doc('alert').onSnapshot(function (doc) {
        let data = doc.data();
        if (data !== undefined) {
          observer.next({
            id: data.id,
            text: data.text,
          });
        } else {
          // what happens if there is no match
          console.error('firebase.service.ts getAlertObservable() data undefined');
        }
      });
    });

  }


}
