// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  version: '1.1.0',
  release: '0.0.2',
  firebase : {
    apiKey: "AIzaSyBopuMHybKUrN9z7fULbHhBfcyi_g_QKok",
    authDomain: "wser-mobile.firebaseapp.com",
    databaseURL: "https://wser-mobile.firebaseio.com",
    projectId: "wser-mobile",
    storageBucket: "wser-mobile.appspot.com",
    messagingSenderId: "354697466608",
    appId: "1:354697466608:web:ae25b3270e5b313fa32835",
    measurementId: "G-DPEKQCNCD4"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
