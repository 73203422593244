import { Component } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Subscription } from 'rxjs';
import { FirebaseService } from './services/firebase.service';
import { Dialog } from '@capacitor/dialog';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public favoriteSub: Subscription;

  constructor(
    private storage: Storage,
    private firebaseService: FirebaseService
    ) {}

  async ngOnInit() {
    // If using a custom driver:
    // await this.storage.defineDriver(MyCustomDriver)
    await this.storage.create();
    this.favoriteSub = this.firebaseService.getAlertObservable().subscribe((latest) => {
      // console.log(latest);
      if (latest.text) {
        this.showAlert(latest);
      }
    });

  }

  async showAlert(latest: any) {
    await Dialog.alert({
      title: 'Update',
      message: latest.text,
    });
  }

}


